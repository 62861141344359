// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-casi-studio-js": () => import("./../../../src/pages/casi-studio.js" /* webpackChunkName: "component---src-pages-casi-studio-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../../../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-servizi-js": () => import("./../../../src/pages/servizi.js" /* webpackChunkName: "component---src-pages-servizi-js" */),
  "component---src-templates-casi-studio-casi-studio-detail-js": () => import("./../../../src/templates/CasiStudio/CasiStudioDetail.js" /* webpackChunkName: "component---src-templates-casi-studio-casi-studio-detail-js" */),
  "component---src-templates-jobs-job-details-js": () => import("./../../../src/templates/Jobs/JobDetails.js" /* webpackChunkName: "component---src-templates-jobs-job-details-js" */),
  "component---src-templates-landing-landing-detail-js": () => import("./../../../src/templates/Landing/LandingDetail.js" /* webpackChunkName: "component---src-templates-landing-landing-detail-js" */),
  "component---src-templates-normal-page-index-js": () => import("./../../../src/templates/NormalPage/index.js" /* webpackChunkName: "component---src-templates-normal-page-index-js" */),
  "component---src-templates-servizi-servizi-detail-js": () => import("./../../../src/templates/Servizi/ServiziDetail.js" /* webpackChunkName: "component---src-templates-servizi-servizi-detail-js" */)
}

